html, body {
    height:              100%;
    overscroll-behavior: none;
}

body {
    margin:                      0;
    font-family:                 var(--font-family), serif;
    font-weight:                 500;
    -webkit-font-smoothing:      antialiased;
    -moz-osx-font-smoothing:     grayscale;
    -webkit-tap-highlight-color: transparent;
}

button {
    cursor: pointer;
}

svg {
    display: block;
}

section {
    display:        flex;
    flex-direction: column;
    gap:            16px;
    align-items:    flex-start;
}

// TODO: REMOVE THIS AFTER MODALS WILL BE IMPLEMENTED
modal-companies-filter, modal-search-filter, subsidiary-company {
    width:           100%;
    height:          100%;
    display:         flex;
    align-items:     flex-end;
    justify-content: center;
    @media (min-width: 768px) {
        align-items: center;
    }
}

.main-container {
    position:   relative;
    min-height: 100vh;
}

// TODO: REMOVE AND ADD TO SKILLS
.highlight {
    color:       var(--dark-green);
    font-weight: 600;
}

/**
* Styles related to search filter panel
*/
.filtering-panel-sections {
    display:        flex;
    flex-direction: column;
    width:          100%;
    overflow-y:     auto;
    height:         100%;
    max-height:     calc(100vh - 145px);

    &::-webkit-scrollbar {
        width: 0;
    }

    @media (min-width: 768px) {
        max-height: calc(100vh - 117px);
    }
}

.filtering-section-content {
    display:        flex;
    flex-direction: column;
    padding:        2px 24px 24px;
    gap:            16px;
}

/**
* Styles pages pagination
*/
.list-pagination {
    display:         flex;
    justify-content: flex-end;
    padding-top:     16px;
}
